import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "black", // HEADER OF THE TABLE
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    backgroundColor: "#36393e", // BODY OF THE TABLE
    color: "white",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    border: 0,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
    backgroundColor: "#36393e", // ALSO BODY OF THE TABLE??
    color: "white",
  },
}));

function createData(name, Default) {
  return { name, Default };
}

const rows = [
  createData("BepInExPack", " v 5.4.2202"),
  createData("AzuAutoStore", " v 2.1.12"),
  createData("AzuCraftyBoxes", "v 1.4.0"),
  createData("SpeedyPaths", "v 1.0.8"),
];

export default function CustomizedTables() {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: "295px", maxWidth: "100%" }}>
        <TableHead>
          <TableRow>
            <StyledTableCell>Modname</StyledTableCell>
            <StyledTableCell style={{ color: "Green" }}>
              Version
            </StyledTableCell>
            <StyledTableCell style={{ color: "Red" }}></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell component="th" scope="row">
                {row.name}
              </StyledTableCell>
              <StyledTableCell>{row.Default}</StyledTableCell>
              <StyledTableCell>{row.Server}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
