import styles from "./Streaming.module.css";
import { TwitchPlayer } from "react-twitch-embed";
import ContentBox from "../components/ContentBox/ContentBox";

const Streaming = () => {
  return (
    <div className={styles.container}>
      <h1 className="Pagetitle">Check out our streamers</h1>
      <div className={styles.wrapper}>
        <ContentBox>
          <h2 style={{ color: "#f9c22e" }}>Roxells</h2>
          <br />
          <h4>About</h4>
          <p>
            Hello I 'm Roxells, I'm a variety streamer from Sweden. I play a lot
            of Valorant!
          </p>
          <TwitchPlayer
            channel="roxells"
            id="roxells"
            theme="dark"
            muted
            width="100%"
            onVideoPause={() => console.log(":(")}
          />
        </ContentBox>
        <ContentBox>
          <h2 style={{ color: "#f9c22e" }}>Mazz</h2>
          <br />
          <h4>About</h4>
          <p>
            Happy gal who loves to play all sorta things: Valorant, Vrising, The
            outlast trials, Dbd etc.
          </p>
          <TwitchPlayer
            channel="mazzclm"
            id="mazzclm"
            theme="dark"
            muted
            width="100%"
            onVideoPause={() => console.log(":(")}
          />
        </ContentBox>
      </div>
    </div>
  );
};

export default Streaming;
