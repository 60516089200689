import SubscriptionOptions from "../components/SusbscriptionOptions/SubscriptionOptions";
import styles from "./Support.module.css";
import ContentBox from "../components/ContentBox/ContentBox";

const Support = () => {
  return (
    <div className={styles.container}>
      <h1 className="Pagetitle">Support</h1>
      <br />
      <h2 className={styles.support}>
        Grab your special role to make you stand out and show your support in
        our community.
      </h2>
      <div className={styles.wrapper}>
        <ContentBox>
          <p>Exclusive Discord Role: Donator </p>
          <div className={styles.badge}>
            <p>Exclusive Discord Badge:</p>
            <img
              src="/badge.png"
              alt="Badge"
              height={50}
              width={50}
              style={{
                margin: "-16px 0px",
              }}
            />
          </div>
          <div>
            <p>
              Exclusive Discord Color: A special
              <span style={{ color: "Yellow" }}> Yellow</span> color in Discord
            </p>
          </div>
          <div>
            <p>Exclusive XP Boost: 20% Increased XP gain in Party Up!</p>
          </div>
          <br />
          <SubscriptionOptions />
        </ContentBox>
      </div>
    </div>
  );
};

export default Support;
