import React from "react";
import styles from "./Discord.module.css";
import discordImage from "../Discord.png";
import ContentBox from "../components/ContentBox/ContentBox";

const Discord = () => {
  return (
    <div className={styles.container}>
      <h1 className="Pagetitle">Our Culture</h1>
      <div className={styles.wrapper}>
        <ContentBox>
          <p>
            We're more than just a group of gamers - we're a family that spans
            the Scandinavian countries. Party Up has been the go-to haven for
            all our members, ranging from ages 20 to 65, all united by a passion
            for gaming.
            <br />
            <br />
            Our community is built on a foundation of safety, respect, and
            camaraderie, welcoming enthusiasts of all games, from the realms of
            Esports to the casual AFK Journey gamer.
            <br />
            <br />
            We pride ourselves on maintaining an environment filled with respect
            and kindness. You'll find a group of like-minded individuals who are
            as enthusiastic about supporting each other as they are about
            gaming.
            <br />
            <br />
            Discover worlds, make friends & create unforgettable memories that
            will last a lifetime with Party Up! Join our ever-growing family.
          </p>
          <button
            style={{ alignSelf: "center" }}
            className={styles.button}
            onClick={() => window.open("https://discord.gg/partyup", "_blank")}
          >
            <img src={discordImage} alt="Discord" height={25} />
          </button>
        </ContentBox>
      </div>
    </div>
  );
};

export default Discord;
