import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import styles from "./SubscriptionOptions.module.css";
import Divider from "@mui/material/Divider";

export default function SubscriptionOptions() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <button
        className={styles.Button}
        onClick={handleClickOpen}
        style={{ width: "auto" }}
      >
        Continue
      </button>
      <Dialog maxWidth="md" fullWidth={true} open={open} onClose={handleClose}>
        <DialogContent className={styles.Dialog}>
          <DialogContentText id="Purcase Options">
            <h3 style={{ color: "#f9c22e", marginBottom: "40px" }}>
              Choose your preferred payment plan to support Party Up!
            </h3>
          </DialogContentText>
          <div className={styles.PurchaseOptionContainer}>
            <div className={styles.PurchaseOption}>
              <div className={styles.PurchaseInfo}>
                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: "1.5rem",
                  }}
                >
                  5$/
                  <span style={{ fontSize: "0.7rem", color: "red" }}>
                    Monthly
                  </span>
                </p>
                <p style={{ fontStyle: "italic", fontWeight: "Bold" }}>
                  Save: 0%
                </p>
              </div>
              <div className={styles.Buttonsize}>
                <button
                  onClick={() =>
                    window.open(
                      "https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-1WA252217R386261LMY2TE5Q",
                      "_blank"
                    )
                  }
                  className={styles.Button}
                >
                  PayPal
                </button>
              </div>
            </div>
            <Divider orientation="vertical" flexItem />
            <div className={styles.PurchaseOption}>
              <div className={styles.PurchaseInfo}>
                <p style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                  14$/
                  <span style={{ fontSize: "0.7rem", color: "red" }}>
                    Quarterly
                  </span>
                </p>
                <p style={{ fontStyle: "italic", fontWeight: "Bold" }}>
                  Save:5%
                </p>
              </div>
              <div className={styles.Buttonsize}>
                <button
                  onClick={() =>
                    window.open(
                      "https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-2DD53899YB5088031MY2TE5Y",
                      "_blank"
                    )
                  }
                  className={styles.Button}
                >
                  PayPal
                </button>
              </div>
            </div>
            <Divider orientation="vertical" flexItem />
            <div className={styles.PurchaseOption}>
              <div className={styles.PurchaseInfo}>
                <p style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                  27$/
                  <span style={{ fontSize: "0.7rem", color: "red" }}>
                    Semi-Yearly
                  </span>
                </p>
                <p style={{ fontStyle: "italic", fontWeight: "Bold" }}>
                  Save: 10%
                </p>
              </div>
              <div className={styles.Buttonsize}>
                <button
                  onClick={() =>
                    window.open(
                      "https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-4E747335AX697202DMY2TE5Y",
                      "_blank"
                    )
                  }
                  className={styles.Button}
                >
                  PayPal
                </button>
              </div>
            </div>
            <Divider orientation="vertical" flexItem />
            <div className={styles.PurchaseOption}>
              <div className={styles.PurchaseInfo}>
                <p style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                  47$/
                  <span style={{ fontSize: "0.7rem", color: "red" }}>
                    Yearly
                  </span>
                </p>
                <p style={{ fontStyle: "italic", fontWeight: "Bold" }}>
                  Save: 20%
                </p>
              </div>
              <div className={styles.Buttonsize}>
                <button
                  onClick={() =>
                    window.open(
                      "https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-5ML43617BX9501401MY2TE6A",
                      "_blank"
                    )
                  }
                  className={styles.Button}
                >
                  PayPal
                  <br />
                </button>
              </div>
            </div>
          </div>
          <p style={{ fontStyle: "italic", marginTop: "2%" }}>
            Send purchase receipt & your Discord name to
            <span style={{ fontWeight: "bold" }}>
              <a
                href="mailto:Info@Partyup.se"
                style={{
                  fontWeight: "bold",
                  textDecoration: "none",
                  color: "#f9c22e",
                }}
              >
                {" "}
                Info@Partyup.se
              </a>
            </span>{" "}
            to activate your new role.
            <br />
          </p>
          <p
            style={{
              fontStyle: "italic",
              fontSize: "12px",
              marginBottom: "-20px",
            }}
          >
            * All purchases are non-refundable
          </p>
        </DialogContent>
        <DialogActions className={styles.Dialog}>
          <button
            className={styles.Button}
            style={{ color: "#36393e", width: "30%", marginBottom: "15px" }}
            onClick={handleClose}
          >
            Close
          </button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
