import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import styles from "./MobileAppBarMenu.module.css";
import ListItem from "@mui/material/ListItem";
import MenuIcon from "@mui/icons-material/Menu";
import ListItemText from "@mui/material/ListItemText";
import logo from "../../puplogo.svg";
import HomeIcon from "@mui/icons-material/Home";
import { Link } from "react-router-dom";

export default function MobileAppBarMenu() {
  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List className={styles.Drawer}>
        {["Discord", "Streaming", "Games", "Merch", "Support"].map((text) => (
          <ListItem key={text}>
            <Link to={`/${text}`}>
              <ListItemText className={styles.NavLinks} primary={text} />
            </Link>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <div className={styles.NavHeader}>
            <a href="/">
              <HomeIcon className="Navlogo" fontSize="large" />
            </a>
            <a href="/">
              <img src={logo} className="Navlogo" alt="logo" />
            </a>
            <MenuIcon
              className={styles.MenuIcon}
              onClick={toggleDrawer(anchor, true)}
            >
              {anchor}
            </MenuIcon>
          </div>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
