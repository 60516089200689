import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useNavigate } from "react-router-dom";

export default function BasicSelect() {
  const [Game, setGame] = React.useState("");
  const navigate = useNavigate();

  const handleChange = (event) => {
    setGame(event.target.value);
    navigate(`/${event.target.value}`);
  };

  return (
    <Box
      sx={{
        minWidth: 225,
        "& .MuiOutlinedInput-root": {
          fieldset: {
            border: "none",
          },
          "&:hover fieldset": {
            border: "none",
          },
          "&.Mui-focused fieldset": {
            border: "none",
          },
        },
        border: "1px solid white",
        borderRadius: "10px",
      }}
    >
      <FormControl fullWidth>
        <InputLabel
          style={{
            color: "white",
            fontFamily: "Retro Gaming",
          }}
        >
          Chose your Game
        </InputLabel>

        <Select value={Game} label="Game" onChange={handleChange}>
          {
            <MenuItem
              value="../Games/Vrising"
              style={{
                color: "black",
                fontFamily: "Retro Gaming",
              }}
            >
              V Rising
            </MenuItem>
          }

          {
            <MenuItem
              value="../Games/Valheim"
              style={{
                color: "black",
                fontFamily: "Retro Gaming",
              }}
            >
              Valheim
            </MenuItem>
          }

          {
            <MenuItem
              value="../Games/Oncehuman"
              style={{
                color: "black",
                fontFamily: "Retro Gaming",
              }}
            >
              Once Human
            </MenuItem>
          }

          <MenuItem
            value="./Games"
            style={{
              color: "#black",
              fontFamily: "Retro Gaming",
            }}
          >
            More Games coming soon..
          </MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
