import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "black", // HEADER OF THE TABLE
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    backgroundColor: "#36393e", // BODY OF THE TABLE
    color: "white",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    border: 0,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
    backgroundColor: "#36393e", // ALSO BODY OF THE TABLE??
    color: "white",
  },
}));

function createData(name, Default, Server) {
  return { name, Default, Server };
}

const rows = [
  createData("Difficulty", "N/A", "Brutal"),
  createData("Teleport Bound Items", "No", "Yes"),
  createData("Sun Damage Modifier", 1, 5),
  createData("Day Start Hour", 9, 11),
  createData("Inventory Stacks Modifier", 1, 3),
  createData("Castle Decay Rate Modifier", 1, 0.25),
  createData("Durability Drain Modifier", 1, 0.25),
  createData("Relic Spawn Type", "Unique", "Plentiful"),
  createData("Global Power Modifier", 1, 1.4),
  createData("VBlood MaxHealth Modifier", 1, 1.25),
  createData("VBlood Power Modifier", 1, 2),
];

export default function CustomizedTables() {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: "250px", maxWidth: "100%" }}>
        <TableHead>
          <TableRow>
            <StyledTableCell>Setting</StyledTableCell>
            <StyledTableCell style={{ color: "Green" }}>
              Default
            </StyledTableCell>
            <StyledTableCell style={{ color: "Red" }}> Server</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell component="th" scope="row">
                {row.name}
              </StyledTableCell>
              <StyledTableCell>{row.Default}</StyledTableCell>
              <StyledTableCell>{row.Server}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
