import styles from "./Footer.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  return (
    <div className={styles.Footer}>
      <div className={styles.wrapper}>
        <div className={styles.CopyRight}>
          <p>Party Up Since 2023.</p>
        </div>
        <a href="mailto:info@partyup.se" className={styles.link}>
          <p className={styles.Contact}>
            Contact Us <FontAwesomeIcon icon={faEnvelope} />{" "}
          </p>
        </a>
      </div>
    </div>
  );
};

export default Footer;
