import React, { useEffect } from "react";

const Merch = () => {
  useEffect(() => {
    // Set the configuration before loading the script
    window.spread_shop_config = {
      shopName: "party-up",
      locale: "en_SE",
      prefix: "https://party-up.myspreadshop.se",
      baseId: "myShop",
    };

    const script = document.createElement("script");
    script.src =
      "https://party-up.myspreadshop.se/shopfiles/shopclient/shopclient.nocache.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      <h1 className="Pagetitle">Merch</h1>
      <div id="myShop">
        <a href="https://party-up.myspreadshop.se">party-up</a>
      </div>
    </div>
  );
};

export default Merch;
