import React from "react";
import styles from "./games.module.css";
import ContentBox from "../components/ContentBox/ContentBox";
import GameSelector from "../components/GameSelector/GameSelector";

const Games = () => {
  return (
    <div className={styles.container}>
      <h1 className="Pagetitle">Games</h1>
      <div className={styles.wrapper}>
        <ContentBox>
          <p style={{ marginBottom: "15px" }}>
            As PartyUp grows and evolves, we are committed to enhancing our
            members' gaming experiences by increasing our investment in
            dedicated servers. This expansion is a direct response to the needs
            and desires of our community, ensuring smoother and more stable
            gameplay across a variety of titles.
          </p>
          <iframe
            style={{ marginBottom: "15px" }}
            width="100%"
            height="285"
            src="https://www.youtube.com/embed/ZrPmBXc6hs4?autoplay=1&mute=1&modestbranding=1&controls=1&si=yHtUMOFWzxHjzLYV&amp;clip=Ugkx7d0D2iGD7HSSU83114mXZnZ5giGT3M-d&amp;"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
          ></iframe>
          <GameSelector />
        </ContentBox>
      </div>
    </div>
  );
};

export default Games;
