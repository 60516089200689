import React from "react";
import styles from "../../pages/Games/Valheim.module.css";
import GameSelector from "../../components/GameSelector/GameSelector";
import ValheimServer from "../../components/Table/ValheimServer";
import ContentBox from "../../components/ContentBox/ContentBox";

const Valheim = () => {
  return (
    <div className={`${styles.container} ${styles.backgroundImage}`}>
      <div style={{ marginTop: "6%" }}>
        <GameSelector className={styles.SelectorStyle} />
      </div>
      <div className={styles.wrapper}>
        <ContentBox>
          <h1 className="Gametitle">Valheim</h1>
          <br />
          <div style={{ color: "white" }}>
            In the vast, untamed wilderness of Valheim, players embark on an
            epic journey through Norse mythology, where survival, exploration,
            and the thrill of combat await.
          </div>
          <br />
          <h3 style={{ color: "#f9c22e" }}>Server Information</h3>
          <ValheimServer />
          <button
            style={{ alignSelf: "center", marginTop: "20px" }}
            className={styles.button}
            onClick={() =>
              window.open(
                "https://discord.com/channels/1112662167129444362/1113411554944241744",
                "_blank"
              )
            }
          >
            Join
          </button>
        </ContentBox>
      </div>
    </div>
  );
};

export default Valheim;
