import React from "react";
import styles from "../../pages/Games/Oncehuman.module.css";
import GameSelector from "../../components/GameSelector/GameSelector";
import ContentBox from "../../components/ContentBox/ContentBox";

const Oncehuman = () => {
  return (
    <div className={`${styles.container} ${styles.backgroundImage}`}>
      <div style={{ marginTop: "6%" }}>
        <GameSelector className={styles.SelectorStyle} />
      </div>
      <div className={styles.wrapper}>
        <ContentBox>
          <h1 className="Gametitle">Once Human</h1>
          <br />
          <div style={{ color: "white" }}>
            Once Human is a multiplayer open-world survival game set in a
            strange, post-apocalyptic future. Unite with friends to fight
            monstrous enemies, uncover secret plots, compete for resources, and
            build your own territory. Once, you were merely human. Now, you have
            the power to remake the world.
          </div>
          <br />
          <h3 style={{ color: "#f9c22e", fontSize: "18px" }}>
            <br></br>
            <span style={{ color: "white" }}>Server:</span> Manibus (NOVICED)
            PVE01-00010
          </h3>

          <button
            style={{ alignSelf: "center", marginTop: "20px" }}
            className={styles.button}
            onClick={() =>
              window.open(
                "https://discord.com/channels/1112662167129444362/1113411554944241744",
                "_blank"
              )
            }
          >
            Join
          </button>
        </ContentBox>
      </div>
    </div>
  );
};

export default Oncehuman;
