import React from "react";
import styles from "./Home.module.css";
import discordImage from "../Discord.png";
import ContentBox from "../components/ContentBox/ContentBox";

const Home = () => {
  return (
    <div className={styles.container}>
      <img src="/pup.svg" className={styles.logo} alt="Party up plaza logo" />
      <div className={`${styles.wrapper} ${styles.Discord}`}>
        <ContentBox>
          <p>
            We're more than just a group of gamers - we're a family that spans
            the Scandinavian countries. PartyUp has been the go-to haven for all
            our members, ranging from ages 20 to 65, all united by a passion for
            gaming.
            <br />
            <br />
            Our community is built on a foundation of safety, respect, and
            camaraderie, welcoming enthusiasts of all games, from the realms of
            Esports to the casual AFK Journey gamer.
            <br />
            <br />
            We pride ourselves on maintaining an environment filled with respect
            and kindness. You'll find a group of like-minded individuals who are
            as enthusiastic about supporting each other as they are about
            gaming.
            <br />
            <br />
          </p>
          <button
            style={{ alignSelf: "center" }}
            className={styles.button}
            onClick={() =>
              window.open("https://discord.gg/fvE78MUAXY", "_blank")
            }
          >
            <img src={discordImage} alt="Discord" height={25} />
          </button>
        </ContentBox>
      </div>
    </div>
  );
};

export default Home;
