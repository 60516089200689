import "./AppBarMenu.css";
import { Link } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";

const AppBarMenu = () => {
  return (
    <div className="NavHeader">
      <a href="/">
        <HomeIcon className="Navlogo" fontSize="large" />
      </a>
      <div className="NavLinks">
        {/* <Link to="/Discord">Discord</Link> */}
        <Link to="/Streaming">Streaming</Link>
        <Link to="/Games">Games</Link>
        <Link to="/Merch">Merch</Link>
        <Link to="/Support">Support</Link>
      </div>
    </div>
  );
};

export default AppBarMenu;
