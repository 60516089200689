import "./App.css";
import { Route, Routes } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import Home from "./pages/Home";
import Discord from "./pages/Discord";
import Streaming from "./pages/Streaming";
import Merch from "./pages/Merch";
import Support from "./pages/Support";
import Games from "./pages/Games";
import Vrising from "./pages/Games/Vrising";
import Valheim from "./pages/Games/Valheim";
import Oncehuman from "./pages/Games/Oncehuman";
import AppBarMenu from "./components/AppBarMenu/AppBarMenu";
import MobileAppBarMenu from "./components/MobileAppBarMenu/MobileAppBarMenu";
import NotFound from "./pages/NotFound";
import Footer from "./components/Footer/Footer";

function App() {
  const isMobile = useMediaQuery("(max-width:700px)");
  return (
    <div className="App">
      {isMobile ? <MobileAppBarMenu /> : <AppBarMenu />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Discord" element={<Discord />} />
        <Route path="/Streaming" element={<Streaming />} />
        <Route path="/Merch" element={<Merch />} />
        <Route path="/Support" element={<Support />} />
        <Route path="/Games" element={<Games />} />
        <Route path="/Games/Vrising" element={<Vrising />} />
        <Route path="/Games/Valheim" element={<Valheim />} />
        <Route path="/Games/Oncehuman" element={<Oncehuman />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
