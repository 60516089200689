import React from "react";
import styles from "../../pages/Games/Vrising.module.css";
import GameSelector from "../../components/GameSelector/GameSelector";
import VrisingPVE from "../../components/Table/VrisingPVE";
import ContentBox from "../../components/ContentBox/ContentBox";

const Vrising = () => {
  return (
    <div className={`${styles.container} ${styles.backgroundImage}`}>
      <div style={{ marginTop: "6%" }}>
        <GameSelector className={styles.SelectorStyle} />
      </div>
      <div className={styles.wrapper}>
        <ContentBox>
          <h1 className="Gametitle">V Rising</h1>
          <br />
          <div style={{ color: "white" }}>
            Join the PvE server and explore the world of V Rising.
          </div>
          <h2>
            <span style={{ color: "Cyan" }}>[PvE]</span> [Brutal] Party Up |
            Official #1
          </h2>
          <br />
          <h3 style={{ color: "#f9c22e" }}>Server Information</h3>
          <VrisingPVE />
          <button
            style={{ alignSelf: "center", marginTop: "20px" }}
            className={styles.button}
            onClick={() =>
              window.open(
                "https://discord.com/channels/1112662167129444362/1113485523940548690",
                "_blank"
              )
            }
          >
            Join
          </button>
        </ContentBox>
      </div>
    </div>
  );
};

export default Vrising;
